import React from 'react'
import Layout from '../../components/Layout'
import get from 'lodash/get'
import { graphql } from 'gatsby'
import HeaderSEO from '../../components/partials/HeaderSEO'

import Spacing from '../../components/partials/Spacing'
import SmallSpacing from '../../components/partials/SmallSpacing'

import kafkaToCoapImg from '../../resources/images/connectors/kafkaToCoap.png'

import ModalImage from 'react-modal-image'
import ThreeBoxSectionConnectors from '../../components/partials/ThreeBoxSectionConnectors'
import { StaticImage } from 'gatsby-plugin-image'

class kafkaToCoap extends React.Component {
    componentDidMount() {
        const script = document.createElement('script')
        script.src = 'https://static.addtoany.com/menu/page.js'
        script.async = true
        this.instance.appendChild(script)

        const script2 = document.createElement('script')
        script2.src = 'https://buttons.github.io/buttons.js'
        script2.async = true
        this.instance.appendChild(script2)
    }

    render() {
        const pageSEO = get(this, 'props.data.allContentfulPages.edges[0].node')
        return (
            <Layout>
                <HeaderSEO pageSEO={pageSEO} />
                {/* <img className="home-fixed-top" src={hero_lens} alt="Home Lense" /> */}
                <section className='hero-margin'>
                    <div className='container-1'>
                        <div className='d-flex flex-wrap mt-3'>
                            <div className='col-md-6 pt-0'>
                                <p className='title breadcrumb-text pt-4'>
                                    <a
                                        href='/architecture-and-integrations/'
                                        className='title_text'>
                                        PRODUCT
                                    </a>{' '}
                                    /
                                    <a
                                        href='/kafka-connectors/'
                                        className='title_text font-weight-bold'>
                                        CONNECTORS
                                    </a>
                                </p>
                                <h1> Connect Kafka to CoAP</h1>
                                <div className='pr-5 mobile-padding-reset'>
                                    <p className='hero-description-dark pr-5 '>
                                        Simplify and speed up your <b> Kafka to CoAP</b> sink with a
                                        Kafka compatible connector via Lenses UI/ CLI, Native plugin
                                        or Helm charts for Kubernetes deployments.
                                    </p>
                                    <div className='mt-2 roll-button'>
                                        <a
                                            className='bg-red text-white  ipad-connector-button'
                                            href='/apache-kafka-docker/?s=kafka-to-coap'>
                                            <span data-hover='Download now with Lenses'>
                                                Download now with Lenses
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 pt-0 ipad-margin-top'>
                                <StaticImage
                                    className='img-fluid mobile-margin-top-half'
                                    src='../../resources/images/screenshots/hero_connectors2.svg'
                                    placeholder='tracedSVG'
                                    alt='Kafka to Coap'
                                />
                            </div>
                        </div>
                    </div>
                </section>

                <Spacing />
                <section>
                    <div className='container-1'>
                        <div className='d-flex flex-wrap'>
                            <div className='col-md-12 mobile-margin-top'>
                                <h2>About the Kafka to CoAP connector</h2>

                                <div className='pt-3 pb-3'>
                                    <a
                                        ref={(el) => (this.instance = el)}
                                        className='github-button'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        href='//github.com/lensesio/stream-reactor'
                                        // datasize='large'
                                        // datashowcount='true'
                                        aria-label='Star lensesio/stream-reactor on GitHub'>
                                        Star
                                        <i className='fa fa-github ml-1'></i>
                                    </a>
                                </div>
                                <p className='font-weight-bold'>License Apache 2.0</p>

                                <p>
                                    This{' '}
                                    <a
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        href='//docs.lenses.io/3.0/connectors/sink/coap.html'>
                                        connector
                                    </a>{' '}
                                    allows you to write messages from Kafka to CoAP by streaming
                                    messages from a Kafka topic and writing them to a CoAP server.
                                    This is an open-source project and so isn&apos;t available with
                                    Lenses support SLAs.
                                </p>

                                <SmallSpacing />
                                <section>
                                    <div className='container-1'>
                                        <h3>Options to connect Kafka to CoAP:</h3>
                                        <ThreeBoxSectionConnectors title='Or Kafka to Coap GitHub Connector' />
                                    </div>
                                </section>
                                <SmallSpacing />
                                <div className='mobile-margin-top'>
                                    <h3 className='h3-like pb-1 mb-0'> Connector benefits</h3>
                                </div>

                                <ul className='blue-checked-list pl-0 pb-0'>
                                    <li>Flexible Deployment</li>
                                    <li>Powerful Integration Syntax</li>
                                    <li>Monitoring & Alerting</li>
                                    <li>Integrate with your GitOps</li>
                                </ul>
                                <SmallSpacing />
                                <div className='mobile-margin-top'>
                                    <h3>Why use Lenses.io to write data from Kafka to CoAP?</h3>

                                    <p>
                                        This connector saves you from learning terminal commands and
                                        endless back-and-forths sinking from Kafka to CoAP by
                                        managing the CoAP stream reactor connector (and all your
                                        other connectors on your Kafka Connect Cluster) through
                                        Lenses.io. Here you can freely monitor, process and deploy
                                        data with the following features:
                                    </p>
                                    <ul className='blue-checked-list pl-0'>
                                        <li>Error handling</li>
                                        <li>Fine-grained permissions</li>
                                        <li>Data observability</li>
                                    </ul>

                                    <ModalImage
                                        className='w-100 mx-auto mt-3'
                                        small={kafkaToCoapImg}
                                        large={kafkaToCoapImg}
                                        alt='Kafka to Coap'
                                    />
                                </div>
                                <SmallSpacing />
                                <div className=' mobile-margin-top'>
                                    <h3>How to push data from Kafka to CoAP</h3>
                                    <p>
                                        The easiest way to try out this is using{' '}
                                        <a href='/apache-kafka-docker/'> Lenses Box</a> as a
                                        pre-configured docker, that comes with this connector
                                        pre-installed.
                                    </p>
                                </div>
                                <div>
                                    <a
                                        className='primary-text'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        href='//docs.lenses.io/3.0/connectors/sink/coap.html'>
                                        Read Docs for the full setup{' '}
                                        <i className='red-pointer-right' />
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className='d-flex flex-wrap pb-5 pl-3'>
                            <div className='col-md-12  p-0 pt-5'>
                                <p className='h4-like'>Share this connector</p>
                            </div>

                            <div className='col-md-12  p-0 '>
                                <div
                                    ref={(el) => (this.instance = el)}
                                    className='a2a_kit a2a_kit_size_32 a2a_default_style mt-1'>
                                    <a className='a2a_button_linkedin a2a_counter '></a>
                                    <a className='a2a_button_twitter a2a_counter pl-2'></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='mobile-margin-bottom'>
                    <div className='container-1 bg-dark rounded-1 p-5'>
                        <div className='d-flex flex-wrap'>
                            <div className='col-md-12 col-12 text-center roll-button'>
                                <p className='mb-5 paragraph-title text-white'>
                                    Experience all features in a single instance always free for
                                    developers
                                </p>
                                <a className='bg-white primary-text' href='/downloads/lenses/'>
                                    <span data-hover='Pull docker free'>Pull docker free</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>

                <Spacing />
            </Layout>
        )
    }
}

export default kafkaToCoap

export const pageQuery = graphql`
    query kafkaToCoap {
        allContentfulPages(
            filter: { pageReference: { pageIdentifier: { eq: "Connector_Kafka_To_Coap" } } }
        ) {
            edges {
                node {
                    title {
                        title
                    }
                    keywords {
                        keywords
                    }
                    description {
                        description
                    }
                    oGimage {
                        fluid(quality: 100) {
                            src
                        }
                    }
                }
            }
        }
    }
`
